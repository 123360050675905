import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const FeatureBlockWrapper = styled.div`
  .image-wrapper {
    padding: 1rem;

    @media screen and (min-width: 768px) {
      width: 100%;
      flex: 0.4;
    }

    .image {
      width: 100%;
    }
  }
  .caption {
    padding: 1rem;

    @media screen and (min-width: 768px) {
      flex: 0.6;
    }

    h2,
    p {
      color: var(--hlc-purple);
    }

    a {
      color: var(--hlc-teal);
    }
  }
`

const FeatureBlock = ({ className, title, text, fileName, link }) => {
  return (
    <FeatureBlockWrapper className={className}>
      <div className="image-wrapper">
        <img className="image" src={fileName} alt={title} />
      </div>
      <div className="caption">
        <h2>{title}</h2>
        <p>
          {text} <Link to={link}>Read More.</Link>
        </p>
      </div>
    </FeatureBlockWrapper>
  )
}

export default FeatureBlock
