import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection } from "../Elements"

const HeroWrapper = styled(PurpleSection)`
  color: var(--hlc-white);

  .solutions-equations ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      // justify-content: space-between;

      .solutions-icons {
        display: flex;
        align-items: center;
        justify-items: space-evenly;

        h2 {
          margin: 0;
          text-align: center;
          width: 40px;
        }
        .headshot-wrapper {
          display: inline-block;
          position: relative;
          width: 40px;
          height: 40px;
          background: var(--hlc-white);
          clip-path: circle(50% at center);

          .headshot-icon {
            position: absolute;
            top: 1px;
            left: 1px;
            width: 38px;
            height: 38px;
            clip-path: circle(50% at center);
          }
        }
      }
    }
  }
`

const SOL01Hero = () => {
  return (
    <HeroWrapper id="SOL01Hero">
      <Grid>
        <div className="grid-content">
          <h1>The Math Is Simple</h1>
          <p>
            With a modular approach to tailoring solutions, it's easy to see how
            our service offering fits in any organization. Simply pick and
            choose the solutions or subject matter experts you require, and
            we'll do the rest.
          </p>
          <div className="solutions-equations"></div>
        </div>
      </Grid>
    </HeroWrapper>
  )
}

export default SOL01Hero
