import React from "react"
import { Layout, Head } from "../components/Layout"
import Banner from "../components/Banner"
import SOL01Hero from "../components/Solutions/SOL01Hero"
import SOL02Features from "../components/Solutions/SOL02Features"
// import SOL03Portfolio from "../components/Solutions/SOL03Portfolio"

const SolutionsPage = () => {
  return (
    <Layout>
      <Head title="Solutions" />
      <Banner imageName="solutions_graded.jpg" title="Solutions" />
      <SOL01Hero />
      <SOL02Features />
      {/* <SOL03Portfolio /> */}
    </Layout>
  )
}
export default SolutionsPage
