import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection, WhiteSection, TealSection } from "../Elements"
import FeatureBlock from "../FeatureBlock"
import landingImage from "../../images/solutions/01_landing.svg"
import ecommerceImage from "../../images/solutions/02_ecommerce.svg"
import strategyImage from "../../images/solutions/03_strategy.svg"

const FeaturesWrapper = styled.section`
  .features-wrapper {
    grid-template-rows: 80px repeat(3, auto);

    @media screen and (min-width: 768px) {
      grid-template-rows: 0.4fr repeat(3, 1fr);
    }

    .bg-transition-before {
      height: 100%;
      grid-column: 1 / 4;
      grid-row: 1 / 2;
      position: relative;
    }

    .bg-transition-before::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      background: var(--hlc-purple);
      transform-origin: bottom left;
      transform: skewY(7deg);
      z-index: -1;
    }

    .bg-transition-after {
      height: 100%;
      grid-column: 1 / 4;
      grid-row: 2 / 5;
      z-index: -2;
    }

    .featured-solution {
      display: flex;
      flex-direction: column;
      grid-column: 2 / 3;
      padding: 1rem;
      margin-bottom: 3rem;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
    }

    .feature-one {
      grid-row: 2 / 3;
    }

    .feature-two {
      grid-row: 3 / 4;
    }

    .feature-three {
      grid-row: 4 / 5;
    }

    .feature-right {
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  }
`

const SOL02Features = () => {
  return (
    <FeaturesWrapper id="SOL02Features">
      <Grid className="features-wrapper">
        <PurpleSection className="bg-transition-before" />
        <WhiteSection className="bg-transition-after bg-chevron-before" />
        <FeatureBlock
          className="featured-solution feature-right feature-one"
          title="Blazing Fast Landing Pages"
          text="Speed is now the driving factor across all major digital touchpoints. User experience, user retention, improving conversions, and even SEO are now a consequence of how fast a website is. This has given rise to a new way of building landing pages: static front ends powered by headless Content Management Systems. At Hunterlily & Co., we've fully embraced the power of speed and use development forward tools such as GatsbyJS, Contentful, and Netlify to build blazing fast landing pages that scale."
          fileName={landingImage}
          link="/solutions/#"
        />
        <FeatureBlock
          className="featured-solution feature-left feature-two"
          title="eCommerce, Modernized."
          text="Your customer's online shopping experience is only as good as your ability to deliver, literally. That's why we take an omnichannel approach to eCommerce, ensuring that all facets of the business are taken into account, including integrations with existing lines of business. We are fluent in Magento 2, WooCommerce, and Shopify."
          fileName={ecommerceImage}
          link="/solutions/#"
        />
        <FeatureBlock
          className="featured-solution feature-right feature-three"
          title="Transformative Digital Strategy"
          text="The most actionable insights are still powerless without delivery of tangible products that users can interact with. The key is combining a lean entrepreneurial approach to problem solving with our wealth of experience in developing enterprise online commerce and experiential applications."
          fileName={strategyImage}
          link="/solutions/#"
        />
      </Grid>
      <TealSection className="bg-chevron-after" />
    </FeaturesWrapper>
  )
}

export default SOL02Features
